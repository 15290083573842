import './App.css';
import React from "react";
import LJ from '../assets/LJ.png';

const App = () => {
    return (
        <div>
           <img src={LJ} alt="Love Julie Logo"></img>
           <h1>Coming Soon...</h1> 
        </div>
    )};

export default App;
